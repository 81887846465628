import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';
import { ChallengeDots } from '../ChallengeDots';
import { ProgressBar } from '../../../../../components-shared/ProgressBar';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { useUser } from '../../../../../contexts/User/UserContext';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';
import { FCWithChildren } from '@wix/challenges-web-library';
import { dataHooks } from '@wix/data-hooks';
import { classes, st } from './ChallengeSummary.st.css';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import { getState } from '../../../../../selectors/participant';
import { isProgramProgressReachedEnd } from '../../../../../selectors/participant/isProgramProgressReachedEnd';
import { getProgress } from '../../../../../selectors/participant/getProgress';

export const summaryDataHooks = dataHooks<{
  main;
  title;
  progressBar;
}>('summary');

export interface ISummary {}

export const ChallengeSummary: FCWithChildren<ISummary> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { participant, participantV3 } = useUser();
  const isSPC = isSelfPaced(challenge);
  const participantOrV3 = participantV3 || participant;
  const resolvedValue = getProgress(participantOrV3);

  const isFinished =
    isUserCompleted(getState(participantOrV3)) ||
    isProgramProgressReachedEnd(
      challenge,
      participantOrV3,
      experiments.enabled('specs.programs.OOIOptimisticUpdateForScheduled'),
    );

  return (
    <div
      className={st(classes.root, {
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        mobile: isMobile,
      })}
      data-hook={summaryDataHooks.main()}
      dir="auto"
    >
      {isFinished ? (
        <p className={classes.completed}>{t('challenge.page.completed')}</p>
      ) : null}
      <h1
        className={classes.title}
        data-hook={summaryDataHooks.title()}
        dir="auto"
      >
        {(challenge as any)?.shouldTranslateTitle
          ? t(challenge.settings.description.title)
          : challenge.settings.description.title}
      </h1>
      <div className={classes.dotsMenu}>
        <ChallengeDots />
      </div>
      {isSPC ? (
        <ProgressBar
          align={settings.get(challengeSettings.sidebarLayoutTextAlignment)}
          className={classes.progress}
          dataHook={summaryDataHooks.progressBar()}
          value={resolvedValue}
        />
      ) : null}
    </div>
  );
};

ChallengeSummary.displayName = 'ChallengeSummary';
